/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    gapable,
    gaps,
    remCalc,
    position,
    layerable,
    colors,
    sizable,
    mq,
} from '../../helpers/stylehelpers';

/**
 * Zitat-Element
 *
 * @param {object} props.author Der Zitat-Autor
 * @param {string} props.className Optional: Zusätzliche CSS-Klassen
 * @param {string} props.children Das Zitat
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.size Optional: die Größe des Containers ('s', 'm', 'l', 'fullWidth')
 *
 * Außerdem werden vom Container folgende Props vererbt:
 * @example
 *      <Quote author="Max Mustermann">
 *          Lorem ipsum ...
 *      </Quote>
 */
export const Quote = styled(({ author, className, children, image }) => (
    <blockquote className={`quote ${className}`}>
        {image && (
            <span className="quote__image">
                <img src={image} alt={author} />
            </span>
        )}
        <span className="quote__quote">{children}</span>
        <cite className="quote__author">
            <span dangerouslySetInnerHTML={{ __html: author }} />
        </cite>
    </blockquote>
))`
    border-top: ${remCalc(4)} solid ${colors.primary};
    color: ${colors.grayDarker};
    display: flex;
    margin-left: ${gaps.m};
    margin-right: ${gaps.m};
    //padding: ${gaps.xl};
    position: relative;
    align-items: stretch;
    justify-items: stretch;
    ${gapable()};
    ${layerable(1)};
    ${sizable('m')};

    ${mq.medium`
        border-top: none; border-left: ${remCalc(40)} solid ${colors.primary};
        margin-left: ${gaps.xxl};
        margin-right: ${gaps.xxl};
    `};

    &::before {
        background-color: ${colors.primary};
        border-radius: 50%;
        display: block;
        content: '“';
        color: ${colors.white};
        font-family: serif;
        font-size: 2em;
        text-align: center;
        line-height: 1.2;
        height: ${remCalc(30)};
        ${position({ top: remCalc(-16), right: remCalc(8) })};
        width: ${remCalc(30)};

        ${mq.medium`
            font-size: 4em;
            height: ${remCalc(65)};
            line-height: 1.1;
            ${position({ top: '1.5rem', left: remCalc(-55) })};
            width: ${remCalc(65)};
        `};
    }

    .quote__quote {
        display: block;
        padding: ${gaps.xl} ${gaps.xl} ${remCalc(80)} ${gaps.xl};
    }

    .quote__author {
        bottom: 0;
        display: block;
        margin-top: 0.5em;
        margin-right: ${gaps.xl};
        padding-bottom: ${gaps.xl};
        position: absolute;
        right: 0;
        text-align: right;

        &::before {
            content: '—';
            display: inline-block;
            font-style: italic;
            margin-right: 0.5em;
        }
    }

    .quote__image {
        overflow: hidden;
        max-width: 120px;
        max-height: 180px;
        flex: 1 0 200px;

        ${mq.medium`
            max-width: 200px;
            max-height: 300px;
        `};

        img {
            display: block;
            width: 100%;
        }
    }
`;

Quote.propTypes = {
    author: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.string,
};

Quote.defaultProps = {
    className: null,
    gap: null,
    size: null,
};
