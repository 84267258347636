import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gapable, position } from '../../helpers/stylehelpers';

const Container = styled.div`
    overflow: hidden;
    padding: ${(9 / 16) * 100}% 0 0;
    position: relative;

    ${gapable()};
`;

const IFrame = styled.iframe`
    ${position({ left: '0', top: '0' })};
    border: 0;
    height: 100%;
    width: 100%;
`;

const Video = ({ src, title, ...props }) => (
    <Container {...props}>
        <IFrame
            width="560"
            height="315"
            src={src}
            title={title}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
        />
    </Container>
);

Video.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Video;
