import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Swipeable from 'react-swipeable';

import { colors, remCalc, icons, gapable, position } from '../../helpers/stylehelpers';

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;

    ${gapable()};
`;

const Inner = styled.div`
    position: relative;
`;

const ItemWrapper = styled.div`
    display: flex;
    transform: translateX(${({ activeItem }) => `-${activeItem * 100}%`});
    transition: 0.25s transform;
`;

const Item = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
`;

const buttonStyle = css`
    background-color: ${colors.blue};
    border: 0;
    cursor: pointer;
    position: absolute;
    width: ${remCalc(40)};
    height: ${remCalc(40)};
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    &:before {
        display: block;
        height: ${remCalc(20)};
        ${position({ top: '50%', left: '50%' })};
        transform: translate(-50%, -50%);
        color: ${colors.white};
    }
`;

const ButtonLeft = styled.button`
    ${buttonStyle};
    left: 0;
    border-top-right-radius: ${remCalc(5)};
    border-bottom-right-radius: ${remCalc(5)};
    &:before {
        content: ${icons.arrowLeft};
    }
`;

const ButtonRight = styled.button`
    ${buttonStyle};
    right: 0;
    border-top-left-radius: ${remCalc(5)};
    border-bottom-left-radius: ${remCalc(5)};
    &:before {
        content: ${icons.arrowRight};
    }
`;

class Slider extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    static defaultProps = {
        className: null,
        gap: null,
    };

    state = {
        activeItem: 0,
    };

    /**
     * Listener für den Klick auf die ArrowButtons
     * @param  {MouseEvent} evt Klick-Event
     */
    onArrowButtonClick = evt => {
        const direction = evt.target.getAttribute('direction');
        this.changeImage(direction);
    };

    /**
     * Listener für Swipe
     * @param  {SwipeEvent} evt    Swipe-Event
     * @param  {Number} deltaX Swipe-Distanz in X-Richtung
     */
    onSwiped = (evt, deltaX) => {
        if (deltaX > 0) {
            this.changeImage('next');
        } else {
            this.changeImage('prev');
        }
    };

    /**
     * Wechselt das Bild der Homestage, in dem es den State manipuliert
     * @param  {String} direction "Richtung" des Bildwechsels
     */
    changeImage = direction => {
        const { activeItem } = this.state;
        const { children } = this.props;
        const itemCount = children.length - 1;
        let nextItem;

        if (direction === 'next') {
            if (activeItem + 1 <= itemCount) {
                nextItem = activeItem + 1;
            } else if (activeItem + 1 > itemCount) {
                nextItem = 0;
            }
        } else if (direction === 'prev') {
            if (activeItem - 1 >= 0) {
                nextItem = activeItem - 1;
            } else if (activeItem - 1 < 0) {
                nextItem = itemCount;
            }
        }

        this.setState({
            activeItem: nextItem,
        });
    };

    /**
     *
     */
    render() {
        const { activeItem } = this.state;
        const { children, className, gap } = this.props;
        const items = children.map((item, index) => (
            <Item key={item.key} number={index}>
                {item}
            </Item>
        ));
        return (
            <Wrapper className={className} gap={gap}>
                <Inner>
                    <ButtonLeft onClick={this.onArrowButtonClick} direction="prev" />
                    <ButtonRight toRight onClick={this.onArrowButtonClick} direction="next" />
                    <Swipeable onSwiped={this.onSwiped}>
                        <ItemWrapper activeItem={activeItem}>{items}</ItemWrapper>
                    </Swipeable>
                </Inner>
            </Wrapper>
        );
    }
}

export default Slider;
