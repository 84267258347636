import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { Headline, P } from '../components/elements/Typography';
import { TextPic } from '../components/elements/TextPic';
import { List, ListItem } from '../components/elements/Lists';
import { Link } from '../components/elements/ButtonsAndLinks';
import { shapeImage } from '../helpers/shapes';
import {
    downloadSjfpPreistrager2018,
    downloadSjfpProjektbeschreibungen2018,
} from '../helpers/downloads';
import { Quote } from '../components/elements/Quote';
import Video from '../components/elements/Video';

const RetrospectPage = ({ data }) => {
    const getImage = name => {
        const edge = data.allFile.edges.find(({ node }) => node.name === name);

        if (edge) {
            return edge.node;
        }

        return null;
    };

    return (
        <BaseLayout
            title="Rückblick"
            description="Rückblick auf den LOTTO Sportjugend-Förderpreis 2018"
        >
            <Stage>
                <StageIntro
                    headline={{
                        text: 'Rückblick: Der LOTTO Sportjugend-Förderpreis 2018',
                        smaller: true,
                    }}
                    copy={
                        <>
                            Der Landessieg geht an den Sport-Club Offenburg<br /> 100.000 Euro für
                            vorbildliche Vereine im Land.
                        </>
                    }
                />
            </Stage>
            <Section container="m">
                <P textAlign="center" bold gap={{ small: 'xl', medium: 'xl', large: 'xxxl' }}>
                    Der Sport-Club Offenburg ist Landessieger beim Lotto Sportjugend-Förderpreis
                    2018. Der Verein sicherte sich den Sieg für sein wegweisendes Mentoringprogramm.
                    Insgesamt 105 Sportvereine aus ganz Baden-Württemberg wurden bei der festlichen
                    Preisverleihung am Samstag, 25. Mai 2019 im Europa-Park für ihre vorbildliche
                    Jugendarbeit gewürdigt. Über 450 Sportvereine aus ganz Baden-Württemberg hatten
                    sich um die mit 100.000 Euro dotierte Auszeichnung beworben.
                </P>

                <P textAlign="center">
                    Elvira Menzer-Haasis, Präsidentin des Landessportverbandes, dankte im Namen des
                    Sports für das finanzielle Engagement von Toto-Lotto und stellte die große
                    Bedeutung des Sports für die Gesellschaft heraus.
                </P>
                <Quote
                    author="Elvira Menzer-Haasis, Präsidentin des Landessportverbandes"
                    gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                >
                    Mit der Verleihung des Sportjugend Förderpreises würdigt Toto-Lotto bereits zum
                    elften Mal besonderen ehrenamtlichen Einsatz von Jugendlichen und für
                    Jugendliche im Sportverein. Der Förderpreis mit einer Preisverleihung in einem
                    würdigen Rahmen und interessanten Preisgeldern setzt ein Zeichen, dass
                    herausragendes Engagement wahrgenommen und auch wertgeschätzt wird. Hierfür
                    möchte ich im Namen aller Sportvereine in Baden-Württemberg Toto-Lotto ganz
                    herzlich Danke sagen.
                </Quote>
                <P textAlign="center">
                    Als Vertreter des Kooperationspartners Kultusministerium sagte Staatssekretär
                    Volker Schebesta
                </P>
                <Quote
                    author="Volker Schebesta, Staatssekretär"
                    gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                >
                    Die Sportvereine sind eine große Klammer unserer Gesellschaft. Soziale Herkunft,
                    Religion und Hautfarbe spielen keine Rolle. Was zählt, sind die Werte des
                    Sports. In den Vereinen werden Fairplay, Toleranz und Teamgeist ganz
                    selbstverständlich vorgelebt. Das macht die Nachwuchs- und Jugendarbeit der
                    Vereine so wichtig für uns alle.
                </Quote>
                <P textAlign="center">Tobias Müller über den Sportjugend-Förderpreis</P>
                <Quote
                    author="Tobias Müller, Vorsitzender der Baden-Württembergischen Sportjugend"
                    gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                >
                    Kindern und Jugendlichen die Möglichkeit zu geben, sich selbst auszuprobieren
                    und dabei Erfahrungen zu sammeln ist ein unverzichtbarer Bestandteil von
                    Persönlichkeitsbildung. In den Sportvereinen in Baden-Württemberg wird dies
                    tagtäglich praktiziert. Wir freuen uns sehr, dass Toto-Lotto diese Arbeit
                    bereits seit zwanzig Jahren in solcher Form würdigt und sichtbar macht.
                </Quote>
                <Headline level="h3" textAlign="center">
                    Impressionen von der Preisverleihung im Europa-Park Rust
                </Headline>
                <Video
                    gap="xl"
                    src="https://baden-tv.com/wp-content/uploads/2019/05/190528_Stellt_Vor_SportjugendForderpreis_FINAL_V2-1.mp4"
                    title="LOTTO Baden-Württemberg verleiht den Sportjugend-Förderpreis 2018 im Europa-Park in Rust"
                />
            </Section>
            <Section container="m">
                <Headline level="h2">Ausgezeichnete Leistungen der Vereine</Headline>
                <P textAlign="center" gap={{ small: 'm', medium: 'l', large: 'xl' }}>
                    <strong>
                        Eine Jury unter dem Vorsitz von Professor Dr. Klaus Bös ermittelte die
                        Preisträger. <br /> Gefragt waren pfiffige ehrenamtliche Aktionen aus den
                        Jahren 2017 und 2018.
                    </strong>
                </P>

                <TextPic
                    image={[
                        getImage('Acoustic Instinct_11'),
                        getImage('Acoustic Instinct_12'),
                        getImage('Alle Regionalsieger'),
                        getImage('Benedikt Doll'),
                        getImage('Elisabeth Seitz'),
                        getImage('Landessieger SC Offenburg'),
                        getImage('Landessieger SC Offenburg_2'),
                        getImage('Landessieger SC Offenburg_3'),
                        getImage('Landessieger SC Offenburg_4'),
                        getImage('lotto-sportjugend-6924'),
                        getImage('lotto-sportjugend-6926'),
                        getImage('Martin Schmitt'),
                        getImage('Martin Schmitt_2'),
                        getImage('Preistraeger Region Donau-Oberschwaben-Bodensee'),
                        getImage('Preistraeger Region Franken'),
                        getImage('Preistraeger Region Hochrhein-Schwarzwald'),
                        getImage('Preistraeger Region Neckar-Alb'),
                        getImage('Preistraeger Region Nordschwarzwald'),
                        getImage('Preistraeger Region Ostwuerttemberg'),
                        getImage('Preistraeger Region Rhein-Neckar-Odenwald'),
                        getImage('Preistraeger Region Stuttgart'),
                        getImage('Preistraeger Region Suedlicher Oberrhein'),
                        getImage('Presitraeger Region Mitttlerer Oberrhein'),
                        getImage('Rope Skipping'),
                        getImage('Rope Skipping TSG Seckenheim_1'),
                        getImage('Rope Skipping TSG Seckenheim_11'),
                        getImage('Rope Skipping TSG Seckenheim_22'),
                        getImage('Sonderpreise'),
                        getImage('Sonderpreistraeger'),
                        getImage('Sonderpreistraeger_2'),
                        getImage('Sporttalk'),
                        getImage('Sporttalk_1'),
                        getImage('Sporttalk_2'),
                        getImage('Sporttalk_3'),
                        getImage('Sporttalk_4'),
                        getImage('TSG Seckenheim Rope Skipping'),
                    ]}
                    alt="Weitere Impressionen"
                >
                    Weitere Impressionen der Preisverleihung
                </TextPic>

                <P>
                    <strong>
                        Einen Überlick über alle Preisträger und ausführliche Projektbeschreibungen
                        erhalten Sie in den nachfolgenden PDFs:
                    </strong>
                </P>

                <List flex>
                    <ListItem type="plain" margin>
                        <Link type="download" to={downloadSjfpPreistrager2018}>
                            Download Preisträger 2018
                        </Link>
                    </ListItem>
                    <ListItem type="plain">
                        <Link type="download" to={downloadSjfpProjektbeschreibungen2018}>
                            Download Projektbeschreibung Sportjugend-Förderpreis 2018
                        </Link>
                    </ListItem>
                </List>
            </Section>
        </BaseLayout>
    );
};

RetrospectPage.propTypes = {
    data: PropTypes.shape({
        allFile: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: shapeImage,
                })
            ),
        }),
    }).isRequired,
};

export default RetrospectPage;

export const query = graphql`
    query RetrospectQuery {
        allFile(filter: { relativePath: { glob: "content/rueckblick/*" } }) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    }
`;
