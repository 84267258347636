/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Img } from './Images';
import Slider from './Slider';
import { FlexContainer } from '../layouts/Containers';
import { shapeImage } from '../../helpers/shapes';
import { gapable, mq } from '../../helpers/stylehelpers';
import { P } from './Typography';

const StyledSlider = styled(Slider)`
    ${mq.mediumDown`
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    `};

    ${gapable()};
`;

/**
 * Bild-Text-Wechsel
 *
 * @param {object} props.alt Der Alternativ-Text für das Bild
 * @param {string} props.className Optional: Zusätzliche CSS-Klassen
 * @param {string} props.children Der Inhalt des Links
 * @param {object|object[]} props.image Das Bild/die Bilder der Box (aus einem graphql/gatsby-image)
 *
 * Außerdem werden vom FlexContainer folgende Props vererbt:
 * @param {string} props.bgColor Optional: die Hintergrundfarbe
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {bool} props.grid Optional: Grid aktivieren
 * @param {bool} props.inline Optional: Container auf inline-flex stellen
 * @param {string} props.justifyContent Optional: Inhalt ab large ausrichten
 * @param {number} props.layer Optional: die Ebene/Größe des Schattens
 * @param {bool} props.padded Optional: Container mit Padding versehen
 * @param {string} props.size Optional: die Größe des Containers ('s', 'm', 'l', 'fullWidth')
 * @param {string} props.textAlign Optional: Ausrichtung des Texts
 *
 * @example
 *      <TextPic rtl image={data.contentUganda.childImageSharp} alt="Fußballcamp in Uganda" gap="l">
 *          Lorem ipsum ...
 *      </TextPic>
 */
export const TextPic = styled(({ className, children, image, alt, ...props }) => (
    <FlexContainer grid className={`textpic ${className}`} {...props}>
        <div>
            <P className="textpic__text" gap={{ small: 'l', medium: 'xxl', large: 'xxxl' }}>
                {children}
            </P>
        </div>
        <div>
            {Array.isArray(image) ? (
                <StyledSlider
                    className={className}
                    gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                >
                    {image.map(img => (
                        <Img
                            alt={alt}
                            src={img.childImageSharp.fluid}
                            key={img.childImageSharp.fluid.src}
                        />
                    ))}
                </StyledSlider>
            ) : (
                <Img
                    className="textpic__img"
                    alt={alt}
                    src={image.childImageSharp.fluid}
                    gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                />
            )}
        </div>
    </FlexContainer>
))`
    ${mq.mediumDown`
        display: block;
        
        .textpic__img {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

    `};
    ${({ rtl }) => (rtl ? `flex-direction: row-reverse` : '')};
`;

TextPic.propTypes = {
    ...FlexContainer.propTypes,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    image: PropTypes.oneOfType([shapeImage, PropTypes.arrayOf(shapeImage)]).isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TextPic.defaultProps = {
    className: null,
    gap: null,
};
